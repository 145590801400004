import { Injectable } from "@angular/core";
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class ExportExcelService {
  get(arg0: string) {
    throw new Error('Method not implemented.');
  }


  constructor(
    private datePipe: DatePipe
  ) { }


  exportExcel_with_header(headers:any, excel_name) {
    const finalHeaders = headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(excel_name, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });
    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        excel_name + "_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

}



