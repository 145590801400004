/* tslint:disable */

import { Injectable } from "@angular/core";



/**

 * Global configuration for Api services

 */

@Injectable({

  providedIn: "root",

})

export class ApiConfiguration {

  configAddLanguage: any;

  configGetLanguages(configGetLanguages: any) {

    throw new Error('Method not implemented.');

  }


  

  // Staging
//baseUrl = 'https://itcdigitalcouponapi.bigcityvoucher.co.in/v1/';


  
  //prod
baseUrl='https://appapi.digitalcoupon.bigcitydays.in/v1/';
  







  // login = this.baseUrl + "login/login";



  login = this.baseUrl + "user/adminlogin";



  gameDetail = this.baseUrl + "reports-panel/gamedetails";

  leaderBoard = this.baseUrl + "reports-panel/leaderboard";

  leaderBoardRefrence = this.baseUrl + "reports-panel/leaderboardreference";

  registered = this.baseUrl + "reports-panel/registered";

  participents = this.baseUrl + "reports-panel/allparticipants";

  teamsPlayed = this.baseUrl + "reports-panel/playerplayedcount";

  coupanDetail = this.baseUrl + "reports-panel/coupondetails";

  voucherDetail = this.baseUrl + "reports-panel/voucherdetails";

  listOfReports = this.baseUrl + "report/list-of-reports";

  reportListing = this.baseUrl + "report/report-listing";

  rejectedReasons = this.baseUrl+'appuser/reject-reasons'

  // Skus

  addSkus = this.baseUrl + "skus/add-skus";

  getBrands = this.baseUrl + "skus/list-skus-master";

  editSkus = this.baseUrl + "skus/edit-skus";

//branch

branchList = this.baseUrl + 'admin/list-branch';
addBranch = this.baseUrl + 'admin/add-branch';
addRegion=this.baseUrl+'admin/add-region';
editBranch = this.baseUrl+'admin/edit-branch';
getbranchForFilter = this.baseUrl +'skus/get-branches';
getRegion = this.baseUrl +'skus/get-regions';
excelUpload = this.baseUrl +'admin/insert-branch';

// campaign

createCampaign = this.baseUrl+'skus/create-campaign';

brandList = this.baseUrl+'skus/get-brands';

downloadusers = this.baseUrl+'skus/campaign-users-list';

uploadusers= this.baseUrl +'skus/campaign-approved-users';

campaignList = this.baseUrl+'skus/get-campaign-list';

downloadCampignTargets = this.baseUrl +'target/retailers-target-excel';

//maker Checker
listMakerCheckers= this.baseUrl + 'admin/maker-checker-details'
generateMakerChecker = this.baseUrl+'admin/maker-checker'
upload = this.baseUrl+'admin/update-status-payment'



  // PackConfig

  getProgramList      = this.baseUrl + "skus/list-program?name=";

  getChildProgramList = this.baseUrl + "skus/list-child-program";

  getParentPrograms   = this.baseUrl+"skus/list-program?master_construct=1";

  addProgram = this.baseUrl +"skus/add-program";
  
  getPack = this.baseUrl + "skus/list-pack-config";

  getGeneratedCoupon = this.baseUrl +"skus/list-coupon-request";

  addPacks = this.baseUrl +"skus/add-pack-config";

  editPack = this.baseUrl +"skus/edit-pack-config";

  // User

  uploadExcelsheet = this.baseUrl + "registration/insertuser";

  getUsersdetail = this.baseUrl + 'registration/readuser';

  serchByname = this.baseUrl + 'registration/readuser?search_by=';

  downloadUserList = this.baseUrl + 'registration/user-details'
  updateUserNumber=this.baseUrl+'registration/update-user-num'

  //Assign Coupons

  assignCoupons = this.baseUrl + "skus/coupon-assignment";

  couponRequest = this.baseUrl + "skus/generate-coupon-request";

  generateCoupon = this.baseUrl + "coupon/genereatecoupons";

  getRolls = this.baseUrl + 'user/get-roles';

  passRollid = this.baseUrl + "registration/readuser?role_id=";

  wdAssignCoupons = this.baseUrl+'target/wd-assignment-list'

  //generate codes



  codeRequest = this.baseUrl + 'skus/generate-reg-coupon';

  generateCode = this.baseUrl + 'skus/generate-codes';

  assignCode = this.baseUrl + 'skus/registration-coupon';

  ListOfCodes = this.baseUrl + 'skus/list-code-request';


  //Inventory Request

  listOfinventory = this.baseUrl + 'skus/list-inventory-request';

  approveRequest = this.baseUrl + 'skus/approve-wd-request';

  rejectRequest = this.baseUrl + 'appuser/reject-request';

  uploadInventory = this.baseUrl +'skus/upload-inventory-request';


  //Targets

  uploadTarget = this.baseUrl + 'target/upload-targets'

  getTragets = this.baseUrl +'target/get-target-files'

  getTragetMaster = this.baseUrl +'target/get-target-master'

  userValidate = this.baseUrl +'registration/show-users'

  insertUpdatedUsers = this.baseUrl+'registration/insert-user-approval'

  approveReject = this.baseUrl + 'registration/validate-users'


  //BranD Master

  getBrandList=this.baseUrl+'brand/list-brand';
  editBrand=this.baseUrl+'brand/edit-brand';
  addBrands=this.baseUrl+'brand/add-brand';


  //reportPanel

  masterDsr            = this.baseUrl +'report/dsr-master';
  masterWd             = this.baseUrl + 'report/wd-master';
  registerRetailer     = this.baseUrl +'report/retailer-registration';
  couponReportWd       = this.baseUrl +'report/wd-coupon-report';
  couponReportDsr      = this.baseUrl + 'report/dsr-coupon-report';
  retailerCouponReport = this.baseUrl + 'report/retailer-coupon-report';
  wdReport             = this.baseUrl + 'report/wd-code-report';
  dsrReport            = this.baseUrl + 'report/dsr-code-report';
  listWdcodes          = this.baseUrl + 'report/list-wd-codes';
  branch               = this.baseUrl + 'report/list-branch';
  wdToDsr              = this.baseUrl+'report/transaction-wd-dsr';
  dsrToRetailer        = this.baseUrl+'report/transaction-dsr-retailer';
  targetAndacheviment  = this.baseUrl+'report/target-achievement-report';
  salesReport          = this.baseUrl+'report/sales-report';
  campaignsList        = this.baseUrl+'skus/list-all-campaign';
  user_export = this.baseUrl+'report/user-export';
  login_report = this.baseUrl+'report/login-report'
marketRegions = this.baseUrl+'report/get-region'
paymentMode = this.baseUrl+'report/payment-modes'
payOuts = this.baseUrl+'report/payout-summary'
performanceReport=this.baseUrl+'report/performance-report'

  // dashboard implemented by Arnab
  listCampaign        = this.baseUrl +'admin/list-campaign';
  listConstrct        = this.baseUrl +'admin/list-construct';
  payoutDetails       = this.baseUrl +'admin/payout-details';
  usersAndCampaigns   = this.baseUrl +'admin/users-and-campaigns';
  userPerformance     = this.baseUrl +'admin/user-performance';
  paymentModes        = this.baseUrl +'admin/payment-modes';
  payoutStatus        = this.baseUrl +'admin/payout-status';

  
  // Base Setting List implemented by Arnab
  baseSettingList    = this.baseUrl +'skus/base-setting-list';
  baseSetting        = this.baseUrl +'skus/base-setting';
  baseSettingUsers   = this.baseUrl +'skus/base-setting-users';
  baseApprovedUsers  = this.baseUrl +'skus/base-approved-users';
  baseSettingTargets = this.baseUrl +'target/base-setting-targets'
  baseSettingBrands  = this.baseUrl +'skus/base-setting-brands'
 

}

export interface ApiConfigurationInterface { }